
.task-detail-content {
    display: flex;
    justify-content: start;
    width: 100%;
    min-width: 1000px;
    height: 100%;
    min-height: calc(100vh - 110px);
    margin-bottom: 10px;
    box-sizing: border-box;
    /*::v-deep .el-scrollbar {*/
        /*min-height: calc(100vh - 110px);*/
        /*background-color: #fff;*/
        /*::v-deep .el-scrollbar__wrap {*/
            /*overflow-x: hidden;*/
        /*}*/
    /*}*/
    .task-detail {
        flex: 1;
        width: calc(100% - 385px);
        height: 100%;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .company-info {
        width: 360px;
        height: 100%;
        min-height: calc(100vh - 110px);
        background: #fff;
        margin-left: 25px;
        padding: 30px;
        box-sizing: border-box;
        .company-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            cursor: pointer;
            .company-cover {
                width: 174px;
                height: 174px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .company-name {
                font-size: 18px;
                font-weight: 500;
                border-bottom:1px solid transparent;
                &:hover {
                    border-bottom:1px solid #2338E6;
                    color: #2338E6;
                }
            }
        }
        .company-detail {
            margin-top: 20px;
            .detail-item {
                display: flex;
                margin-bottom: 25px;
                .title {
                    min-width: 70px;
                    margin-right: 5px;
                }
                a {
                    color: #584EEE;
                }
                .determine-btn {
                    background-color: #584EEE;
                    border-color: #584EEE;
                    padding: 7px 14px;
                }
            }
        }
        .task-goods {
            display: flex;
            justify-content: center;
            .publish-num {
                margin-right: 32px;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 20px;
                .num {
                    color: #9ACC5B;
                    font-size: 24px;
                    margin-top: 10px;
                }
            }
        }
    }
}
.task-content {
    padding: 20px;
    background-color: #fff;
    .task-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        color: #333;
        font-weight: 500;
        height: 50px;
        border-bottom: 1px solid #E5E5E5;
        padding: 0 15px;
        .text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
            margin-right: 10px;
        }
        .bounty {
            display: block;
            width: 80px;
            color: #FF0000;
        }
    }
    .task-info {
        display: flex;
        justify-content: space-between;
        padding: 20px 15px;
        border-bottom: 1px solid #E5E5E5;
        ul {
            font-size: 14px;
            color: #666666;
            li {
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .task-status {
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            justify-content: space-around;
            .account-btn {
                font-size: 16px;
                width: 100px;
                height: 36px;
                line-height: 36px;
            }
            .new-account-btn {
                font-size: 16px;
                width: 100px;
                height: 36px;
                line-height: 36px;
                margin-left: 10px;
            }
            .new-disAccount-btn {
                font-size: 16px;
                width: 100px;
                height: 36px;
                line-height: 36px;
                margin-left: 10px;
                background: #FF0000;
            }
            .time-out {
                width: 100px;
                height: 36px;
                line-height: 36px;
                font-size: 16px;
                background-color: #DCDCDC;
                text-align: center;
                border-radius: 2px;
            }
            .time-ing {
                width: 100px;
                height: 36px;
                line-height: 36px;
                font-size: 16px;
                background-color: #9ACC5B;
                color: #fff;
                text-align: center;
                border-radius: 2px;
            }
            img {
                width: 80px;
                height: 29px;
            }
        }
    }
    .task-require {
        padding: 20px 15px;
        border-bottom: 1px solid #E5E5E5;
        .title {
            margin-bottom: 15px;
            font-size: 16px;
            .left-icon {
                display: inline-block;
                width: 3px;
                height: 10px;
                background-color: #00B47F;
                margin-right: 6px;
            }
        }
        .require-content {
            color: #666;
            font-size: 14px;
        }
    }
    .task-goods {
        padding: 20px 15px;
        border-bottom: 1px solid #E5E5E5;
        .title {
            margin-bottom: 15px;
            font-size: 16px;
            .left-icon {
                display: inline-block;
                width: 3px;
                height: 10px;
                background-color: #00B47F;
                margin-right: 6px;
            }
        }
        .goods-content {
            display: flex;
            align-items: center;
            .goods-cover {
                width: 80px;
                height: 80px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 15px;
                img {
                    max-width: 80px;
                    max-height: 80px;
                }
            }
            .goods-info {
                display: flex;
                flex-direction: column;
                .goods-title {
                    font-size: 14px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }
                .price {
                    color: #FF0000;
                    font-weight: 500;
                }
            }
        }
    }
    .task-annex {
        padding: 20px 15px 0;
        border-bottom: 1px solid #E5E5E5;
        .title {
            margin-bottom: 15px;
            font-size: 16px;
            .left-icon {
                display: inline-block;
                width: 3px;
                height: 10px;
                background-color: #00B47F;
                margin-right: 6px;
            }
        }
        .annex-content {
            display: flex;
            justify-content: start;
            flex-wrap: wrap;
            li {
                margin-bottom: 15px;
                width: 50%;
                .file-name {
                    float: left;
                    display: block;
                    max-width: 300px;
                    margin-right: 30px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;
                    border-bottom:1px solid transparent;
                    i {
                        color: #409EFF;
                    }
                }
                .down-progress {
                    display: flex;
                    align-items: center;
                    .download {
                        color: #2338E6;
                        width: 50px;
                        margin-right: 20px;
                        cursor: pointer;
                        border-bottom:1px solid transparent;
                        .download-bg {
                            display: block;
                            width: 20px;
                            height: 20px;
                            background-color: #409EFF;
                            color: #fff;
                            border-radius: 4px;
                            line-height: 20px;
                            text-align: center;
                            i {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
            .script-name {
                cursor: pointer;
                border-bottom:1px solid transparent;
                &:hover {
                    border-bottom:1px solid #2338E6;
                    color: #2338E6;
                }
            }
        }
    }
}
.task-submit {
    padding: 20px 30px;
    margin: 20px 0;
    background-color: #fff;
    .title {
        margin-bottom: 15px;
        font-size: 16px;
        .left-icon {
            display: inline-block;
            width: 3px;
            height: 10px;
            background-color: #00B47F;
            margin-right: 6px;
        }
    }
    .file-list {
        display: flex;
        flex-wrap: wrap;
        margin: 15px 0;
        li {
            width: 205px;
            height: 40px;
            background-color: #EEEEEE;
            color: #666666;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 15px;
            margin: 10px 10px 10px 0;
            box-sizing: border-box;
            .file-type {
                display: flex;
                align-items: center;
                .file-name {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                    width: 120px;
                }
                i {
                    color: #BFBFBF;
                    font-size: 24px;
                    margin-right: 10px;
                }
            }
            .file-text {
                display: flex;
                align-items: center;
                i {
                    font-size: 12px;
                    margin-left: 10px;
                    cursor: pointer;
                }
            }
        }
    }
    .el-textarea {
        margin-bottom: 15px;
    }
    .account-btn {
        display: flex;
        margin-top: 15px;
    }
}
.task-submit-list {
    display: flex;
    width: 100%;
    margin-top: 20px;
    ::v-deep .el-tab-pane {
        height: 100%;
    }
    ::v-deep .el-tabs {
        flex: 1;
        width: 1%;
    }
    ::v-deep.el-tabs{
        height: 100%;
        box-shadow: none;
        border: none;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        background: transparent;
    }
    ::v-deep.el-tabs__header{
        border-bottom: none;
        margin: 0;
    }
    ::v-deep.el-tabs__content{
        height: 1%;
        flex: 1;
        background: #fff;
        padding-top: 20px;
        margin-bottom: 10px;
    }
    ::v-deep.el-tabs__header .is-active {
        background: #fff;
        font-size: 16px;
        font-weight: 400;
        color: #0824ce;
        border-radius: 6px 6px 0 0;
    }
    ::v-deep.el-tabs__item{
        height: 52px;
        line-height: 52px;
    }
    ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__nav{
        border: none;
    }
    ::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item {
        padding: 0 30px;
        border-left: none;
    }
    .submissions{
        width: 100%;
        background: #ffffff;
        overflow: hidden;
        .submissions-title{
            margin: 24px;
            color: #333333;
            font-size: 16px;
            font-weight:400;
        }
        .class-select{
            font-size: 14px;
            span{
                display: inline-block;
                color: #333333;
                margin-left: 24px;
                margin-bottom: 30px;
            }
            ::v-deep .el-select{
                height: 28px;
                margin-left: 10px;
                width: 135px;
            }

        }
        .tableList{
            span{
                color: #666666;
                font-size:14px;
                font-weight: 400;
            }
        }
    }
}
.upload-btn {
    position: relative;
    input {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        cursor: pointer;
    }
}
.apply-dialog {
    ::v-deep .el-dialog__header {
        border-bottom: 1px solid #EAEAEA;
    }
    .el-select {
        margin-left: 15px;
    }
    .account-list {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        .el-radio {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            ::v-deep .el-radio__label {
                display: flex;
                align-items: center;
            }
        }
        .avatar-img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-right: 10px;
        }
        .no-data {
            display: block;
            text-align: center;
        }
    }
}
.dialog-footer {
    .el-button {
        padding: 8px 34px;
    }
    .el-button--primary {
        background-color: #2338E6;
        border: 1px solid #2338E6;
        &:hover {
            background-color: #1b2dbf;
            border: 1px solid #1b2dbf;
        }
    }
}
.annex-dialog {
    ::v-deep .el-dialog__header {
        border-bottom: 1px solid #EAEAEA;
    }
    ::v-deep .el-dialog__body {
        padding: 0 20px;
    }
    .annex-list {
        height: 330px;
        ::v-deep .el-tabs__nav-wrap::after {
            height: 1px;
            background-color: #EEEEEE;
        }
        ::v-deep .el-tabs__item {
            height: 45px;
            line-height: 45px;
            font-size: 14px;
            font-weight: 400;
            border: none;
            color: #5F5C74;
        }
        ::v-deep .el-tabs__item.is-active {
            color: #16113F;
        }

        ::v-deep .el-tabs__active-bar {
            background: #9ACC5B;
        }

        ::v-deep .el-tabs__active-bar:before {
            position: absolute;
            top: -13px;
            left: 40%;
            content: '';
            width: 0;
            height: 0;
            border-style: dashed dashed solid dashed;
            border-width: 7px;
            border-color: transparent transparent #9ACC5B transparent;
        }
        .annex-tab {
            height: 100%;
            ::v-deep .el-tabs__content {
                height: calc(100% - 60px);
                ::v-deep .el-tab-pane {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }
            }
        }
    }
}
.upload-file-list {
    height: calc(100vh - 640px);
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
        .el-scrollbar__view {
            height: 100%;
        }
    }
    .file-item {
        display: flex;
        justify-content: space-between;
        padding: 20px 0 10px;
        .file-type {
            i {
                margin-right: 10px;
            }
        }
        .file-text {
            margin-right: 10px;
            span {
                margin-right: 10px;
            }
            i {
                font-size: 14px;
                cursor: pointer;
                &:hover {
                    color: #1b2dbf;
                }
            }
        }
    }
}
.link-list {
    .link-item {
        display: flex;
        align-items: center;
        margin: 20px 40px 20px 0;
        .link-text {
            display: block;
            width: 85px;
            text-align: right;
            margin-right: 15px;
        }
    }
}
.clip-library {
    height: 100%;
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
        .el-scrollbar__view {
            height: 100%;
        }
    }
    .item-list {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        .clip-checkbox {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            ::v-deep .el-checkbox__input {
                position: absolute;
                bottom: 0;
            }
            ::v-deep .el-checkbox__label {
                display: flex;
                flex-direction: column;
                height: 120px;
                position: relative;
                padding-left: 0;
                justify-content: space-between;
                .video-name {
                    display: block;
                    width: 130px;
                    padding-left: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
    .video-no-data {
        height: 100%;
        width: 100%;
        text-align: center;
        line-height: 270px;
    }
    .item {
        width: 150px;
        height: 120px;
        margin: 0 10px;
    }
}
.platform-select {
    width: 200px;
}
.img-dialog {
    ::v-deep .el-dialog {
        .el-dialog__header {
            border-bottom: 1px solid #EAEAEA;
        }
        .el-dialog__body {
            height: 550px;
            padding: 0;
            .img-content {
                display: flex;
                height: 100%;
                .big-img {
                    flex: 1;
                    width: 1%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    /*max-height: 524px;*/
                    .show-img {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 524px;
                        height: 524px;
                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                }
                .small-img {
                    display: flex;
                    flex-wrap: wrap;
                    width: 336px;
                    border-left: 1px solid #EEEEEE;
                    .el-scrollbar {
                        height: 100%;
                        width: 100%;
                    }
                    .el-scrollbar__wrap {
                        overflow-x: hidden;
                        .el-scrollbar__view {
                            display: flex;
                            flex-wrap: wrap;
                            box-sizing: border-box;
                        }
                    }
                    .img-div {
                        display: flex;
                        justify-content: center;
                        width: 128px;
                        height: 128px;
                        padding: 20px;
                        cursor: pointer;
                        img {
                            max-width: 100%;
                            max-height: 100%;
                            border-radius: 10px;
                        }
                    }
                }
            }
        }
    }
}
.script-table{
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    margin-right: 150px;
    .script-table-row{
        display: flex;
        &:nth-child(1){
            line-height: 1;
            .script-table-column{
                padding: 18px 0;
            }
        }
        .script-table-column{
            display: flex;
            flex: 1;
            width: 1%;
            justify-content: center;
            align-items: center;
            padding: 20px 0;
            margin-right: 3px;
        }
        &:nth-child(odd){
            .script-table-column{
                background: #D3DDFF;
            }
        }
        &:nth-child(even){
            .script-table-column{
                background: #F0F3FF;
            }
        }
    }
}
::v-deep.annex-list-dialog {
    border-radius: 6px;
    .el-dialog__header{
        display: none;
    }
    ::v-deep .el-dialog__body {
        padding: 30px;
    }
}
.view-script-main{
    display: flex;
    flex-direction: column;
    height: 100%;
    //::v-deep .el-scrollbar__wrap {
    //  overflow-x: hidden;
    //}
    .view-script-item-top{
        display: flex;
        //align-items: center;
        .view-script-img{
            width:390px;
            height:224px;
            margin-right:30px;
            img{
                max-width:390px;
                max-height:224px;
                border-radius: 10px;
            }
        }
        .view-script-right{
            flex: 1;
            width: 1%;
            .view-script-name{
                margin-top: 10px;
                font-size: 18px;
                color: #333;
            }
            .view-script-fenlei{
                font-size: 16px;
                color: #666;
                margin-top: 30px;
            }
            .view-script-jianjie{
                font-size: 16px;
                color: #666;
                margin-top: 8px;
                line-height: 30px;
            }
        }
    }
    .view-script-body{
        display: flex;
        flex-direction: column;
        border: 2px solid #584EEE;
        //box-shadow: 0px 10px 0px 0px #ECEBFF;
        border-radius:20px;
        margin:48px 0 30px;
        .view-script-body-title{
            width: 160px;
            height: 32px;
            background: #584EEE;
            border-radius: 16px;
            line-height: 32px;
            text-align: center;
            color: #fff;
            margin: -12px 0 0 24px;
            position: relative;
            &:before{
                content: "";
                background: #fff;
                -webkit-border-radius: 50%;
                border-radius: 50%;
                width: 8px;
                height: 8px;
                position: absolute;
                top: 12px;
                left: 20px
            }
            &:after{
                content: "";
                background: #fff;
                -webkit-border-radius: 50%;
                border-radius: 50%;
                width: 8px;
                height: 8px;
                position: absolute;
                top: 12px;
                right: 20px
            }
        }
        .view-script-body-content{
            display: flex;
            min-height: 100px;
            padding-left: 22px;
        }
    }

    ::v-deep.el-table{
        .warning-row {
            background: #ECEBFF;
        }
    }
    .dialog-footer {
        text-align: center;
        .el-button {
            padding: 10px 36px;
            margin-bottom: 0;
        }
        .el-button--primary {
            background-color: #2338E6;
            border: 1px solid #2338E6;
            &:hover {
                background-color: #1b2dbf;
                border: 1px solid #1b2dbf;
            }
        }
    }
}
.script-dialog-table{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    height: 265px;
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .dialog-table-row{
        display: flex;
        &:nth-child(odd){
            background: #DEDCFD;
        }
        &:nth-child(even){
            background: #F8F7FF;
        }
        &:nth-child(1){
            .dialog-table-column{
                font-size: 14px;
                color: #222222;
                font-weight: 400;
            }
        }
        .dialog-table-column{
            display: flex;
            flex: 1;
            width: 1%;
            justify-content: center;
            align-items: center;
            height: 50px;
        }
    }
}
.train-evaluete {
    padding: 20px 15px 0;
    margin-right: 20px;
    .title {
        margin-bottom: 15px;
        font-size: 16px;
        .left-icon {
            display: inline-block;
            width: 3px;
            height: 10px;
            background-color: #00B47F;
            margin-right: 6px;
        }
    }
}
.is-qualified, .no-qualified{
    font-size: 38px;
    line-height: 1;
    &.is-qualified{
        color: #20C997;
    }
    &.no-qualified{
        color: #FF0000;
    }
}
